@import '~antd/dist/antd.min.css';

body {
  background: #f0f2f5;
}

.site-layout-background {
  background: #f0f2f5;
}

.ant-layout-header {
  background: white;
}

.layout-block {
  background: white;
}

.editable {
  &:not(.editing):not(.loading) {
    background: transparent;
    border: 1px solid transparent;
  }
}

.editing {
  position: sticky;
  min-width: 200px;
  height: 32px;
  z-index: 10;
}

.has-editable-fields {
  // see `src/common/headerCell.ts`
  padding-left: 19px !important;

  .ant-table-column-sorters {
    padding-left: 0 !important;
  }
}

.module {
  width: 170px;
  overflow: hidden;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.comment-box {
  padding: 9px 0;
  border-bottom: 0.1px solid lightgrey;
  //border-radius: 5px;
}

.normal-link {
  display: block;
  line-height: normal;
}

.compact-form {
  .ant-form-item {
    margin-bottom: 8px;
  }
}

.ant-form-item-label > label {
  white-space: break-spaces;
}
