.logo {
  display:      flex;
  align-items:  center;
  margin-right: 28px;
  color:        inherit;
  > img {
    margin-top:   -25px;
    width:        115px;
    margin-left:  -25px;
    margin-right: 5px;
  }
  .logo-right {
    display: flex;
    flex-grow: 1;
    .version {
      margin-left: auto;
      opacity:     .5;
    }
  }
  .wordmark {
    line-height: normal;
    .brand, .tagline {
      display: block;
    }
    .brand {
      font-weight: 600;
      font-size:   133%;
    }
    .tagline {
      margin-top:    -2px;
      font-size:     95%;
      opacity:       .7;
      margin-bottom: 2px;
    }
  }
  &.compact {
    margin-right: 10px;
    img {
      width:       80px;
      margin-top:  0;
      margin-left: -22px;
    }
    .wordmark {
      transform-origin: left center;
      transform:        scale(.9);
    }
  }
}
