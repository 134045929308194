.custom-icon {
  display:         inline-flex;
  align-items:     center;
  justify-content: center;
  width:           1.3em;
  svg {
    width:      15px;
    height:     15px;
    max-width:  1.3em;
    max-height: 1.05em;
    path {
      fill: currentColor;
    }
  }
}
