header {
  margin-top: 40px;
  display:    flex;
  .ant-menu {
    flex:          1;
    margin-top:    0;
    border-bottom: none;
  }

  .ant-menu-item.compact-menu-item {
    margin-left:  10px !important;
    margin-right: 10px !important;
  }

  /* menu items only in the 'fixed' menu - not the automatic dropdown on narrow screens */
  .menu-item-link {
    padding: 0 5px;
  }
}

.ant-menu-overflowed-submenu {
  margin-left: auto !important;
  > div {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

/* menu items in both contexts - 'fixed' menu as well as automatic dropdown */
.menu-item-link {
  display: flex;
  .custom-icon {
    margin: 0 5px 0 -2px;
  }
}

.ant-menu-submenu-popup {
  .nav-menu-logout {
    a > div {
      height: 40px !important;
    }
  }
}